<template>
  <v-container class="text-center" height="500" justify="center">
    <v-form>
      <h1>Login</h1>
      <error-message v-if="message" :error="message" />
      <error-message v-if="error" :error="error" />
      <v-text-field 
        v-model="username" 
        width="200px" 
        outlined 
        label="Name"
        @keyup.enter="login" 
      />

      <v-text-field
        v-model="password"
        width="200px"
        outlined
        label="Passwort"
        @click:append="show1 = !show1"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show1 ? 'text' : 'password'"
        @keyup.enter="login"
      />

      <v-btn @click="login">Login </v-btn>
    </v-form>
  </v-container>
</template>

<script>
// import {myLoginRoutine} from "@/components/auth.js"
import utils from "@/utils";
import errorMessage from "../components/errorMessage.vue";

export default {
  components: { errorMessage },
  data() {
    return {
      password: null,
      username: null,
      error: null,
      show1: false,
    };
  },
  props: ["message"],
  computed: {
  },
  methods: {
    async login() {
      this.error = false
      let response = await utils.loginToBackend(this.username, this.password);
      if (response === "logged in") {
        this.$router.push({ name: "AdminMarkets" });
      } else {
        console.log(response);
        this.error = "Username or password are wrong"
      }
    }
  }
};
</script>

<style scoped>
.form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 70px 0;
}
.col {
  border: 2px solid green !important;
}
</style>
